
import {useState, useEffect} from 'react';
import { Elements,
      useStripe,
       useElements,
    PaymentElement,
} from '@stripe/react-stripe-js';
import { 
     Button,
     notification
     } from 'antd';
import { loadStripe } from "@stripe/stripe-js";

const StripeInner = ({clientSecret, amount, setPaymentsucceeded}) => {
   
    const [status, setStatus] = useState('new');
    const stripe = useStripe(); 
    const elements = useElements();
    const [notify, notifyContext] = notification.useNotification();
    if (!stripe || !elements) {
        return;
    }
    const paymentHandler = async (e) => {
        setStatus('processing');
        e.preventDefault();
        const paymentResult = await stripe.confirmPayment({elements, redirect:'if_required'});
        console.log(paymentResult);
        if (paymentResult.error) {
            setStatus('failed');
            console.log(paymentResult.error.message);
            notify['error']({
                message: 'Payment Failed',
                description: 'The payment was declined. Please check that your card number, expiry date and CVC are correct.',
                duration: 60,
            });
        } else {
            if (paymentResult.paymentIntent.status === 'succeeded') {
                setStatus('succeeded');
                setPaymentsucceeded(true);
            } else {
                console.log('Case 3 -  not an error, but not succeeded');
            }
        }
    }
    return (
        <>
        {notifyContext}
        <form onSubmit={paymentHandler}>
            <PaymentElement/>
            { (status === 'succeeded' ) 
            ? (<></>)
            :(     <Button type="primary" htmlType="submit" loading={(status === "processing")}>Pay Now</Button>)
    }
        </form>
        </>
    )
}


const StripeForm = ({url, invoice, setPaymentsucceeded, amount, setAmount}) => {
    const [clientSecret, setClientSecret] = useState("");
    const [reference, setReference] = useState("");
    const [payee, setPayee] = useState("");
    const [notify, notifyContext] = notification.useNotification();
    useEffect(() => {
        fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(invoice ),
        })
            .then((res) => res.json())
            .then((data) => {
                setClientSecret(data.clientSecret);
                setReference(data.reference);
                setAmount(data.amount);
                setPayee(data.payee);
            })
            .catch((err)=>{
                notify['error']({
                    message: 'Server Problem',
                    description: 'Try again later',
                    duration: 60,
                });
            });
    }, [url, invoice, notify,amount, setAmount]);
    
    const publishable_key = window.ENV_STRIPE_PUBLISHABLE_KEY;
    console.log('publishable_key', publishable_key);
    console.log('ENV_BUILD', window.ENV_BUILD);
    const stripePromise = loadStripe(publishable_key);

    const appearance = {
        theme: 'stripe',
              variables: {
          colorText: '#000000',
          colorDanger: '#df1b21',
          spacingUnit: '2px',
        },
      };
      
    const options = { clientSecret, appearance};

   
    return (
        <>
        {notifyContext}
        <div className='creditcard' >
        
         {clientSecret !== "" && (
          <Elements options={options} stripe={stripePromise}>
              <p>{payee} </p>
          <p>{amount} (Ref: {reference}) </p>
          <StripeInner clientSecret={clientSecret} setPaymentsucceeded={setPaymentsucceeded}></StripeInner>  
          </Elements>
)}
        </div>
        </>
    );
        
}


export default StripeForm;
