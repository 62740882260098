import './App.css';
import Entry from './Nordic/Entry';
import Display from './Nordic/Display';
import banner from './Nordic/banner.jpg';
import { useState } from 'react';
import StripeForm  from "./Stripe/StripeForm";

import { ConfigProvider } from 'antd';
import { Image } from 'antd';
function App() {
  const [readytopay, setReadytopay] = useState(false);
  const [values, setValues] = useState({});
  const [paymentsucceeded, setPaymentsucceeded] = useState(false);
  const [amount, setAmount] = useState("");

  return (
         <ConfigProvider
        theme={{
  "token": {
    "colorPrimary": "#1b75b9",
    "colorInfo": "#1b75b9"
  },
        }}>
        <div className="App" style={{maxWidth: 600, padding: 0 }}>

         <Image src={banner}/>
          
        {readytopay 
         ? (<>
            <Display values={values}/>
            {paymentsucceeded
            ? (values.renewal
              ? (
            <p><b>{amount} Payment Successful</b><br/>
            Thank you for renewing your membership of NSW Nordic Ski Club.<br/>
            You may close this window now.</p> 
              )
              : (
                <p><b>{amount} Payment Successful</b><br/>
                Welcome to NSW Nordic Ski Club! <br/>
                We will email within 2 business days with information about your membership account.<br/>
                You may close this window now.</p>
              )
            )
            :(   <StripeForm url="/api/invoices" amount={amount} setAmount={setAmount} invoice={values} setPaymentsucceeded={setPaymentsucceeded}/>)
}
           </>)
         : (<Entry values={values} setValues={setValues}  setReadytopay={setReadytopay}/>)
        }
          
        </div>
            </ConfigProvider>
  );
}

export default App;
