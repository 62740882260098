import React, { useState } from 'react';

import { 
    Radio,
    Input,
    Form,
    Space,
    Button,
    DatePicker,
    Checkbox,
    Typography,
    notification,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const Entry = ({values, setValues,readytopay,setReadytopay}) => {
  const [family, setFamily] = useState(0);
  const [form] = Form.useForm();
  const [waiver, setWaiver] = useState(false);
  const [waiverPartner, setWaiverPartner] = useState(false);
  const [notify, notifyContext] = notification.useNotification();
    const handleFormValuesChange = (changedValues) => {
        const fieldName = Object.keys(changedValues)[0];
    
        if(fieldName === "family"){
            const x = changedValues[fieldName];
            setFamily(x);
        }
    }
  const onWaiverChange = async (e) => {
      await setWaiver(e.target.checked);
  };  
  const validateWaiver = (rule, value, callback) => {
    if(waiver) {
        return callback()
    }
    return callback("Please accept the waiver")
};
const onWaiverPartnerChange = async (e) => {
  await setWaiverPartner(e.target.checked);
};  
const validateWaiverPartner = (rule, value, callback) => {
if(waiverPartner) {
    return callback()
}
return callback("Please accept the waiver")
};

const onFinish = (values) => {
  if (values.family === 1 && values.children){
    values.children.forEach((child) => {
      child.birthday = child.dateofbirth.$D + '/' + (child.dateofbirth.$M + 1) + '/' + child.dateofbirth.$y;
    });
  }
    console.log(values);
    setValues(values);
    setReadytopay(true);
  }
  
  const onFinishFailed = () => {
    notify['warning']({
      message: 'Please check for missing entry',
      description:
        'Check your inputs, and submit again.',
      duration: 60,
    });
    
  }


  return (        
    <>
    {notifyContext}
    <Form
    form={form}
    name="basic"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    initialValues={{ remember: true }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
    onValuesChange={handleFormValuesChange}
    >
        <Form.Item name="renewal" label="Membership Type" rules={[{ required: true, message: 'Please select New Member or renewing member'}]}>
    <Radio.Group >
      <Radio value={0}>New Membership</Radio>
      <Radio value={1}>Renewal</Radio>
    </Radio.Group>
    </Form.Item>

    <Form.Item name="family" label=" " rules={[{ required: true}]}>
   <Radio.Group >
    <Radio value={0}>Individual</Radio>
    <Radio value={1}>Family</Radio>
  </Radio.Group>
  </Form.Item>

    <Form.Item label='First Name' name="firstname"rules={[{ required: true, message: 'Please input your name!' }]} >
        <Input placeholder="Jack" />
    </Form.Item>

    <Form.Item label='Last Name' name="lastname" rules={[{ required: true, message: 'Please input your name!' }]} >
        <Input placeholder="Smith" />
   </Form.Item>
   <b>Postal Address:</b>
   <Form.Item label='Street' name="street" rules={[{ required: true, message: 'Please enter your street or GPO box!' }]}>
      <Input placeholder="8 Patamba street" />
  </Form.Item>

  <Form.Item label='Suburb' name="suburb" rules={[{ required: true, message: 'Please enter your suburb!' }]}>
      <Input placeholder="Jindabyne" />
  </Form.Item>

  <Form.Item label='State' name="state" rules={[{ required: true, message: 'Please enter your state!' }]}>
      <Input placeholder="NSW" />
  </Form.Item>
  <Form.Item label='Postcode' name="postcode" rules={[{ required: true, message: 'Please enter your postcode!' }]}>
      <Input placeholder="2428" />
  </Form.Item>

  
  <Form.Item label='Residential Suburb' name='residence'>
      <Input placeholder="Suburb (for car sharing)" />
  </Form.Item>
 
  <Form.Item label='Mobile Phone' name="mobilephone" rules={[{ required: true, message: 'Please enter your mobile number!' }]}>
      <Input placeholder="0419 123 456" />
  </Form.Item>
 
  <Form.Item label='Other Phone' name="otherphone">
      <Input placeholder="+61 2 1234 5678" />
  </Form.Item>
 
  <Form.Item label='Email' name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
      <Input type="email" placeholder="jack.smith@nordix.org.au" />
  </Form.Item>

    <Form.Item name="kicknglide" label="Kick and Glide Newsletter " rules={[{ required: true}]}>
   <Radio.Group >
    <Radio value={1}>email please</Radio>
    <Radio value={0}>I'll get it from the website.</Radio>
  </Radio.Group>
  </Form.Item>


  <Typography>
The NSW Nordic Ski Club’s membership list is available to NSC members on the NSC website to help organise trips and car sharing. We recommend you tick the box below to be included in the list. Only your name, residential suburb and phone number(s) will be shared.
</Typography>
    <Form.Item name="publish" label="Contact Details" rules={[{ required: true}]}>
   <Radio.Group >
    <Radio value={1}>share</Radio>
    <Radio value={0}>private</Radio>
  </Radio.Group>
  </Form.Item>







{family ? ( <>
  <h2> Partner's information </h2> 
  <Form.Item label='First Name' name="partnerfirstname"rules={[{ required: true, message: 'Please input your name!' }]} >
        <Input placeholder="Jack" />
    </Form.Item>

    <Form.Item label='Last Name' name="partnerlastname" rules={[{ required: true, message: 'Please input your name!' }]} >
        <Input placeholder="Smith" />
   </Form.Item>

 
  <Form.Item label='Mobile Phone' name="partnermobilephone" rules={[{ required: true, message: 'Please enter your partner\'s mobile number!' }]}>
      <Input placeholder="0408 675 309" />
  </Form.Item>
 
  <Form.Item label='Other Phone' name="partnerotherphone">
      <Input placeholder="+61 2 8765 4321" />
  </Form.Item>
 
  <Form.Item label='Email' name="partneremail" rules={[{ required: true, message: 'Please enter your partner\'s email!' }]}>
      <Input type="email" placeholder="Joanne.Smith@nordix.org.au" />
  </Form.Item>

    <Form.Item name="partnerkicknglide" label="Kick and Glide Newsletter " rules={[{ required: true}]}>
   <Radio.Group >
    <Radio value={1}>email please</Radio>
    <Radio value={0}>I'll get it from the website.</Radio>
  </Radio.Group>
  </Form.Item>


    <Form.Item name="partnerpublish" label="Contact Details" rules={[{ required: true}]}>
   <Radio.Group >
    <Radio value={1}>share</Radio>
    <Radio value={0}>private</Radio>
  </Radio.Group>
  </Form.Item>

  <h2>Children</h2> 
  <Form.List name="children">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{
                display: 'flex',
                marginBottom: 8,
              }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'firstname']}
                rules={[
                  {
                    required: true,
                    message: 'Missing first name',
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'lastname']}
                rules={[
                  {
                    required: true,
                    message: 'Missing last name',
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'dateofbirth']}
                rules={[
                  {
                    required: true,
                    message: 'Missing date of birth',
                  },
                ]}
              >
                <DatePicker format="DD/MM/YYYY" showTime={false} />
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
        
          <Form.Item>
            <Button shape='round' size='small' type='primary' onClick={() => add()} icon={<PlusOutlined />}>
              Add Child
            </Button>
          </Form.Item>
        </>
      )}

      
    </Form.List>
  
    </>):(<></>)}
    <Typography>
 <b> Acknowledgement of Risk</b><br/>
In consideration for the New South Wales Nordic Ski Club Inc. (“NSC”) allowing me to attend and participate in NSC trips, I advise and confirm that: I understand and acknowledge: <br/>
• skiing is a potentially hazardous and dangerous activity, which may cause physical injury or death;<br/>
• those risks include but are not limited to hypothermia, dehydration, sunburn, muscle strain, fatigue from travelling long distances and/or carrying heavy loads for long periods over steep terrain, injury from colliding with rocks or trees or walking or skiing on slippery and/or uneven surfaces, being hit by rocks, snow and/or ice from above, falling from cliffs steep slopes, exposure to cold and/or bad weather including wind, rain, snow, blizzards and white-out, being delayed or disoriented by weather conditions, injury to or fatigue of others and/or equipment failure or failure to use equipment correctly;<br/>
• my participation in NSC activities constitutes my voluntary assumption of the risks inherent in cross-country skiing and the related activities of the NSC;<br/>
• I will endeavour to ensure that any NSC activity which I undertake is within my capabilities and that I will carry food, water and equipment appropriate for that NSC activity;<br/>
• I will be responsible for my own safety when participating in NSC activities; and<br/>
• I will notify the NSC trip coordinator of any medical or other conditions which may affect my participation in the NSC activity.<br/>
I release the NSC, its Committee, officers and trip coordinators from any liability arising out of any death, injury, damage, loss, claim, costs, demands, or expense which I may suffer directly or indirectly in connection with my participation in any NSC activity whether or not caused by the negligence of the NSC, its Committee, officers or trip coordinators.
</Typography>
  <Form.Item label="I agree" name="waiver"
                         rules={[{validator: validateWaiver}]}>
                <Checkbox checked={waiver} onChange={onWaiverChange}></Checkbox>
  </Form.Item>

  {family ? ( <>
    <Typography>
<b>Acknowledgement of Risk on behalf of partner and children</b><br/>
I confirm that my partner has read and understood the Acknowledgement Of Risk (above) and that I am authorised to accept this on behalf of them and the children named in
</Typography>
<Form.Item label="I agree" name="partnerwaiver" 
                         rules={[{validator: validateWaiverPartner}]}>
                         <Checkbox checked={waiverPartner} onChange={onWaiverPartnerChange}></Checkbox>
</Form.Item>   
    </>):(<></>)}

 

    <Form.Item >
        <Button type="primary" htmlType="submit">Submit</Button>
      </Form.Item>

    
  </Form>
  </>


  );

};

export default Entry;


