
import { 
    Descriptions
} from 'antd';


const Display = ({values}) => {
    const membership = (values.renewal ? 'Renew' : 'New' ) +
    (values.family ? ' Family' : ' Single') +
  ' Membership';
  const items = [
    {key: 1, label: "Name", children: values.firstname + ' ' + values.lastname },
    {key: 2, label: "Mail Address", children: values.street +', ' + values.suburb +' '+ values.state +' '+values.postcode},
    {key: 3, label: "Suburb", children: values.residence },
    {key: 4, label: "Mobile", children: values.mobilephone},
    {key: 5, label: "Other",  children: values.otherphone},
    {key: 6, label: "Email",  children: values.email},
    {key: 7, label: "Kick-n-Glide", children: (values.kicknglide?'Email':'No')},
    {key: 8, label: "Contact Info"  , children: (values.publish?'Share':'Private')},
      ];
   
    const partneritems = values.family ?
    [
        {key: 1, label: "Name", children: values.partnerfirstname + ' ' + values.partnerlastname },
        {key: 2, label: "Mobile", children: values.partnermobilephone},
        {key: 3, label: "Other",  children: values.partnerotherphone},
        {key: 4, label: "Email",  children: values.partneremail},
        {key: 5, label: "Kick-n-Glide", children: (values.partnerkicknglide?'Email':'No')},
        {key: 6, label: "Contact Info"  , children: (values.partnerpublish?'Share':'Private')},
          ]
          : [];

    const childitems = (values.children && values.children.length > 0) ?
    values.children.map((child,i) => {
        return {key: i, label: child.firstname + ' ' + child.lastname, children:  child.dateofbirth.$y};
    })
    : [];

    const hasChildren = (childitems.length > 0);
  
   return (<div className="display">
  <Descriptions title={membership} items={items} column={1} layout="horizontal" bordered={false}/>
  {values.family ? 
    (<Descriptions title="Partner" items={partneritems} column={1} layout="horizontal" bordered={false}/>)
    : (<></>)}
  {hasChildren ?
    (<Descriptions title="Children" items={childitems} column={1} layout="horizontal" bordered={false}/>)
    :(<></>)
  }
  </div>
  ) };
  
export default Display;
